<template>
  <GModal v-show="showModal" name="modal" class="modal modal-quick-view">
    <div slot="header">
      <a href class="modal-dismiss" @click.prevent="close">
        <img src="/static/img/vm-cola/close.svg" />
      </a>
    </div>
    <div slot="body">
      <div class="view-detail">
        <div class="view-detail__images">
          <div v-if="images" class="product-thumb-container">
            <div class="product-thumb-wrapper">
              <div class="item">
                <img
                  v-if="images.url"
                  :data-src="images.url"
                  :src="images.url"
                  class="photo-thumbs__img swiper-lazy"
                  height="240"
                  width="240"
                  alt="Product Image"
                />
              </div>
            </div>
          </div>
          <span v-if="discount" class="view-detail__images__label-discount">{{ discountLabel }}</span>
          <span v-if="!isStockAvailable" class="view-detail__images__out-of-stock">OUT OF STOCK</span>
        </div>

        <div v-if="product" class="view-detail__content">
          <h5 v-if="product.brand" class="view-detail__brand-name">{{ product.brand.name }}</h5>
          <p class="view-detail__product-name">{{ product.name }}</p>
          <p class="view-detail__product-combination">{{ combinationAttributes }}</p>
          <div v-if="finalPrice" class="view-detail__price">
            <template v-if="discount && priceBeforeDiscount">
              <span v-if="amountDisc" class="view-detail__price-stroke">Rp {{ ammountCur }}</span>
              <span v-else class="view-detail__price-stroke">{{ priceBeforeDiscount }}</span>
            </template>
            <span
              class="view-detail__final-price"
              :class="{ 'view-detail__final-price--after-discount': discount && priceBeforeDiscount }"
            >
              {{ finalPrice }}
            </span>
            <div v-if="discount" class="view-detail__price-discount">{{ discount }}</div>
          </div>

          <div class="view-detail__info">
            <div class="block-snippet-product">
              <div class="rating">
                <div
                  class="rs big-star"
                  :class="[
                    'new-rs-' +
                      $options.filters.newRating(Number.parseFloat($get(product, 'review_stats.average_rating', 0))) *
                        10,
                  ]"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <span class="view-detail__ratings">{{ productRating.toFixed(1) }}</span>
                <span class="view-detail__total-reviews"
                  >({{
                    $options.filters.nFormatter(Number.parseFloat($get(product, 'review_stats.total_reviews', 0)))
                  }})</span
                >
              </div>

              <div v-if="productRating">
                <span class="view-detail__total-reviews-detail">
                  <strong>{{ product.review_stats.total_recommended_count }}</strong
                  >&nbsp; {{ product.review_stats.total_recommended_count > 1 ? 'reviewers' : 'reviewer' }} recommend
                  this
                </span>
              </div>
            </div>
          </div>

          <div class="view-detail__combinations">
            <div class="combination action-addtobag">
              <div class="combination__list">
                <div class="input-addon">
                  <a
                    href
                    class="minus"
                    :class="{ disabled: Number(qty) === 1, 'soldout-disabled': !isStockAvailable }"
                    @click.prevent="reduceQty"
                  ></a>
                  <input
                    v-model="qty"
                    type="text"
                    name="qty"
                    class="form-control"
                    :disabled="!isStockAvailable"
                    readonly
                  />
                  <a
                    href
                    class="plus"
                    :class="{ disabled: Number(qty) === Number(maxQty) || !isStockAvailable }"
                    @click.prevent="addQty"
                  ></a>
                </div>
                <div class="button-group">
                  <template v-if="isStockAvailable">
                    <button v-if="!isProcessingAddToBag" class="button-add-to-bag" @click.prevent="addToBag">
                      ADD TO BAG
                    </button>
                    <loaderSpin v-else />
                  </template>
                  <template v-else>
                    <button class="button-add-to-bag disabled" disabled="disabled">ADD TO BAG</button>
                  </template>
                </div>
              </div>
              <p v-if="lastStock" class="last-stock">Last stock</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </GModal>
</template>

<script>
import ls from '@/components/helpers/local-storage';

export default {
  name: 'ModalQuickView',
  data() {
    return {
      qty: 1,
      isProcessingAddToBag: false,
      amountValue: 0,
      amountDisc: 0,
      ammountCur: 0,
    };
  },
  computed: {
    currentCombination() {
      return this.$store.state.vmCola.quickView.currentCombination;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    combinationAttributes() {
      if (this.product.default_combination && this.product.default_combination.attributes) {
        let combination_name = '';
        const attributes = this.product.default_combination.attributes;
        for (const attribute in attributes) {
          if (this.product.default_combination.attributes.hasOwnProperty(attribute)) {
            if (attribute !== 'non_specify') {
              combination_name = attributes[attribute].name;
            }
          }
        }
        return combination_name;
      }
    },
    images() {
      const images = JSON.parse(JSON.stringify(this.currentCombination.images[0]));
      if (images) {
        return images;
      }
      return [{ url: '/static/img/vm-cola/dummy_product.svg' }];
    },
    productId() {
      return this.$store.state.vmCola.quickView.productId;
    },
    product() {
      return this.$store.state.vmCola.quickView.data;
    },
    productRating() {
      let result = 0;
      try {
        result = this.product.review_stats.average_rating;
      } catch (error) {}
      return result;
    },
    showModal() {
      return this.$store.state.vmCola.quickView.isShow;
    },
    discount() {
      const prefix = '';
      let discount = '';
      if (this.currentCombination) {
        // prefix = "SAVE";

        if (
          this.currentCombination.deduction_type &&
          this.currentCombination[`deduction_${this.currentCombination.deduction_type}`]
        ) {
          if (this.currentCombination.deduction_type === 'amount') {
            discount = '-Rp. ' + this.$options.filters.currency(this.currentCombination.deduction_amount);
          } else {
            discount = '-' + this.currentCombination.deduction_percentage + '%';
          }

          return `${prefix} ${discount}`;
        }
      }
      return null;
    },
    discountDeduction() {
      if (this.currentCombination) {
        return this.currentCombination.deduction_type;
      }
      return null;
    },
    discountLabel() {
      let discount = '';
      if (this.currentCombination) {
        if (this.product.is_special_price_applicable) {
          return 'SALE';
        }
        if (
          this.currentCombination.deduction_type &&
          this.currentCombination[`deduction_${this.currentCombination.deduction_type}`]
        ) {
          if (this.currentCombination.deduction_type === 'amount') {
            discount = 'SALE';
          } else {
            discount = this.currentCombination.deduction_percentage + '%';
          }

          return `${discount}`;
        }
      }
      return null;
    },
    finalPrice() {
      if (this.currentCombination) {
        if (this.currentCombination.deduction_type) {
          if (this.currentCombination.price_after_discount) {
            return `Rp ${this.$options.filters.currency(this.currentCombination.price_after_discount)}`;
          } else {
            let price_after_discount;
            if (this.currentCombination.deduction_type === 'percentage') {
              price_after_discount = this.currentCombination.price * this.discountDeduction;
            } else {
              price_after_discount = this.currentCombination.price - this.discountDeduction;
            }
            return `Rp ${this.$options.filters.currency(price_after_discount)}`;
          }
        } else {
          if (this.currentCombination.price) {
            return `Rp ${this.$options.filters.currency(this.currentCombination.price)}`;
          }
          return this.product.price ? `Rp ${this.$options.filters.currency(this.product.price)}` : '';
        }
      }
      return null;
    },
    priceBeforeDiscount() {
      if (this.currentCombination) {
        return `Rp ${this.$options.filters.currency(this.currentCombination.price)}`;
      }
      return null;
    },
    isStockAvailable() {
      //var result = (this.product.is_in_stock && !this.product.is_out_of_stock_sociolla) || (!this.product.default_combination.is_out_of_stock_sociolla && this.stock)
      const result = this.stock && this.maxQty > 0;
      if (!result) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.qty = 0;
      }
      return result;
    },
    lastStock() {
      return this.maxQty === 1;
    },
    stock() {
      return this.product.vending_stock ? Number(this.product.vending_stock) : 0;
    },
    qty_on_cart() {
      let qty_product_on_cart = 0;
      if (ls.get('cart')) {
        const carts = JSON.parse(ls.get('cart'));
        if (carts && carts.length) {
          carts.forEach((res) => {
            if (res.raw.detail.vending_slot == this.product.vending_slot) {
              qty_product_on_cart += Number(res.qty);
            }
          });
        }
      }
      return qty_product_on_cart;
    },
    maxQty() {
      return Number(this.product.vending_stock) - this.qty_on_cart;
    },
    availableOnCart() {
      if (ls.get('cart')) {
        const cart = JSON.parse(ls.get('cart'));
        if (cart && cart.length) {
          const item = cart.find((x) => x.raw.detail.vending_slot === this.product.vending_slot);
          if (item) {
            return item;
          }
        }
      }
      return {};
    },
  },
  watch: {
    showModal(newVal, oldVal) {
      if (newVal) {
        return newVal;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('SET_QUICK_VIEW_DATA', {});
    this.$store.commit('HIDE_MODAL', 'quickView');
  },
  methods: {
    deleteUnuseData(prod) {
      delete prod.review_stats;
      delete prod.categories;
      delete prod.discounts;
      delete prod.bogo_rules;
      delete prod.parent_category;
      delete prod.max_discount;
      return prod;
    },
    addToBag(type = '') {
      if (this.availableOnCart && Object.keys(this.availableOnCart).length && this.availableOnCart.qty) {
        const qtyTotal = this.availableOnCart.qty + this.qty;
        if (Number(qtyTotal) > this.stock) {
          this.$toasted.global.error('Produk melebihi batas maksimal.');
          return;
        }
      }

      const product = {
        id: this.productId,
        combination_id: this.currentCombination['_id'],
        quantity: this.qty,
        slot: this.product.vending_slot,
      };

      if (this.product.is_special_price_applicable) {
        (product.is_special_price = true), (product.special_price_voucher_id = this.product.special_price_voucher_id);
      }

      product._id = this.product._id;
      product.detail = this.deleteUnuseData(this.product);
      if (product.detail['combinations'] && product.detail['combinations'].length) {
        product.detail['combinations'] = product.detail['combinations'].map((prod) => this.deleteUnuseData(prod));
      }
      if (product.detail['default_combination'] && Object.entries(product.detail['default_combination']).length) {
        product.detail['default_combination'] = this.deleteUnuseData(product.detail['default_combination']);
      }

      this.close();
      const combination__name = [];
      for (const i in this.currentCombination.attributes) {
        combination__name.push(`${i}: ${this.currentCombination.attributes[i].name}`);
      }

      this.$store.commit('SET_NOTIFICATION_ADD_CART_DATA', this.product);
      this.$store.commit(
        'SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME',
        this.setCombinationName(combination__name.join(', '))
      );
      this.$store.commit('SHOW_MODAL', 'notificationAddCart');
      this.$store.dispatch('addToCartNoLogin', {
        product: product,
        combination: this.currentCombination,
        combination_name: this.setCombinationName(combination__name.join(', ')),
        with_popup: true,
      });

      setTimeout(() => {
        this.$store.commit('HIDE_MODAL', 'notificationAddCart');
        this.$store.commit('SET_NOTIFICATION_ADD_CART_DATA', null);
        this.$store.commit('SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME', null);
      }, 3000);
      return;

      if (!type) {
        this.isProcessingAddToBag = true;
      }

      axios
        .post(`${Config.MS_CART_API_URL}/user/me/carts`, {
          product,
          action: 'add',
        })
        .then((response) => {
          if (response.status === 200) {
            const combination__name = [];
            for (const i in this.currentCombination.attributes) {
              combination__name.push(`${i}: ${this.currentCombination.attributes[i].name}`);
            }
            this.$store.commit('SET_NOTIFICATION_ADD_CART_DATA', this.product);
            this.$store.commit(
              'SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME',
              this.setCombinationName(combination__name.join(', '))
            );
            this.$store.commit('SHOW_MODAL', 'notificationAddCart');
            // Update Mini Cart
            const update_footer_cart = {
              product: {
                id: this.productId,
                combination_id: this.currentCombination['_id'],
                quantity: this.qty,
              },
              action: 'add',
            };
            if (this.product.is_special_price_applicable) {
              (update_footer_cart.product.is_special_price = true),
                (update_footer_cart.product.special_price_voucher_id = this.product.special_price_voucher_id);
            }
            update_footer_cart.product._id = this.product._id;
            update_footer_cart.product.detail = this.product;
            update_footer_cart.combination = this.currentCombination;
            this.$store.dispatch('setFooterCart', update_footer_cart);
            this.$store.dispatch('fetchCurrentUserCart');
            if (this.$route.path === '/vmcola/order') {
              this.$store.dispatch('fetchCurrentUserQtyCart');
            }
            this.close();
            if (type == 'buy') {
              this.$store.commit('HIDE_MODAL', 'notificationAddCart');
              this.$store.commit('SET_NOTIFICATION_ADD_CART_DATA', null);
              this.$store.commit('SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME', null);
              this.$router.push('/vmcola/order');
            } else {
              setTimeout(() => {
                this.$store.commit('HIDE_MODAL', 'notificationAddCart');
                this.$store.commit('SET_NOTIFICATION_ADD_CART_DATA', null);
                this.$store.commit('SET_NOTIFICATION_ADD_CART_COMBINATION_GROUP_NAME', null);
              }, 3000);
            }
          } else {
            this.$toasted.global.error('Silakan coba lagi dalam beberapa saat.');
          }
          this.isProcessingAddToBag = false;
        })
        .catch((err) => {
          // console.log(err);
          this.$toasted.global.error(
            err.response.data.message ? err.response.data.message : 'Silakan coba lagi dalam beberapa saat.'
          );
          this.isProcessingAddToBag = false;
        });
    },
    applyStateEachAttribute(selectedAttribute, selectedAttributeName) {
      const listCodeForActiveCombinations = [];
      const selectedCode = `${selectedAttributeName}_${selectedAttribute.id}`;
      const listActiveCombinations = { size: [], variant: [], shade: [] };

      for (const code in this.product.list_combinations_with_code) {
        if (this.product.list_combinations_with_code.hasOwnProperty(code)) {
          if (new RegExp(selectedCode).test(code)) {
            listCodeForActiveCombinations.push(code);
          }
        }
      }

      listCodeForActiveCombinations.forEach((combination_code, key) => {
        const arrAttribute = combination_code.split('-');
        arrAttribute.forEach((attribute_code, key) => {
          const attribute = attribute_code.split('_');
          const attribute_name = attribute[0];
          const attribute_my_sociolla_sql_id = attribute[1];

          if (listActiveCombinations[attribute_name].indexOf(attribute_my_sociolla_sql_id) < 0) {
            listActiveCombinations[attribute_name].push(attribute_my_sociolla_sql_id);
          }
        });
      });

      const totalCombination = listCodeForActiveCombinations[0].split('-').length;

      if (totalCombination > 1) {
        if (listActiveCombinations.size.length) {
          this.product.list_combinations.size.forEach((attribute, key) => {
            const isDisabledValue = listActiveCombinations.size.indexOf(String(attribute.id)) > -1 ? false : true;

            this.product.list_combinations.size[key].isDisabled = isDisabledValue;
          });
        }

        if (listActiveCombinations.variant.length) {
          this.product.list_combinations.variant.forEach((attribute, key) => {
            const isDisabledValue = listActiveCombinations.variant.indexOf(String(attribute.id)) > -1 ? false : true;
            this.product.list_combinations.variant[key].isDisabled = isDisabledValue;
          });
        }

        if (listActiveCombinations.shade.length) {
          this.product.list_combinations.shade.forEach((attribute, key) => {
            const isDisabledValue = listActiveCombinations.shade.indexOf(String(attribute.id)) > -1 ? false : true;
            this.product.list_combinations.shade[key].isDisabled = isDisabledValue;
          });
        }
      }
    },
    close() {
      this.$store.commit('SET_QUICK_VIEW_DATA', {});
      this.$store.commit('HIDE_MODAL', 'quickView');
    },
    reduceQty() {
      this.qty--;
    },
    addQty() {
      this.qty++;
    },
    setCombinationName(code) {
      let result = '';
      try {
        const split_1 = code.split(',');
        const split_2 = split_1[0].split(':');
        const split_3 = split_1[1].split(':');
        const text_1 = `<b>${split_2[0].replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        })}</b>: ${split_2[1]}`;
        const text_2 = `<b>${split_3[0].replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        })}</b>: ${split_3[1]}`;
        result = `${text_1}<br>${text_2}`;
      } catch (e) {
        const split = code.split(':');
        result = `<b>${split[0].replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        })}</b>: ${split[1]}`;
      }

      return result;
    },
    updateCurrentCombination(new_attribute, attribute_name) {
      const currentCombination = JSON.parse(JSON.stringify(this.currentCombination));
      let currentCombinationCode = '';
      const attributeCode = `${attribute_name}_${new_attribute.id}`;

      currentCombination.attributes[attribute_name] = JSON.parse(JSON.stringify(new_attribute));
      if (currentCombination.attributes && Object.keys(currentCombination.attributes).length) {
        for (const attribute in currentCombination.attributes) {
          if (currentCombination.attributes.hasOwnProperty(attribute)) {
            if (attribute !== 'non_specify') {
              currentCombinationCode = currentCombinationCode ? currentCombinationCode + '-' : currentCombinationCode;
              currentCombinationCode += `${attribute}_${currentCombination.attributes[attribute].id}`;
            }
          }
        }
      }

      if (this.product.list_combinations_with_code[currentCombinationCode]) {
        const newAttribute = JSON.parse(
          JSON.stringify(this.product.list_combinations_with_code[currentCombinationCode])
        );
        this.$store.commit('SET_QUICK_VIEW_CURRENT_COMBINATION', newAttribute);
        this.applyStateEachAttribute(new_attribute, attribute_name);
      } else {
        const newListCombinations = [];
        let newAttribute;
        if (this.product.list_combinations_with_code && Object.keys(this.product.list_combinations_with_code).length) {
          for (const code in this.product.list_combinations_with_code) {
            if (this.product.list_combinations_with_code.hasOwnProperty(code)) {
              if (new RegExp(attributeCode).test(code)) {
                const newCombination = JSON.parse(JSON.stringify(this.product.list_combinations_with_code[code]));
                newListCombinations.push(newCombination);
              }
            }
          }

          newListCombinations.forEach((combination, key) => {
            currentCombinationCode = '';
            if (key === 0) {
              for (const attribute in combination.attributes) {
                if (combination.attributes.hasOwnProperty(attribute)) {
                  if (attribute !== 'non_specify') {
                    currentCombinationCode = currentCombinationCode
                      ? currentCombinationCode + '-'
                      : currentCombinationCode;
                    currentCombinationCode += `${attribute}_${combination.attributes[attribute].id}`;
                  }
                }
              }
            }
          });
          this.$store.commit('SET_QUICK_VIEW_CURRENT_COMBINATION', newListCombinations[0]);
          newAttribute = JSON.parse(JSON.stringify(newListCombinations[0].attributes[attribute_name]));
          this.applyStateEachAttribute(newAttribute, attribute_name);
        }
      }

      this.qty = 1;
    },
    setAmount(amount) {
      if (amount.price_discount) {
        this.amountDisc = amount.price_discount;
      }
      this.amountValue = amount.price;
      this.ammountCur = `${this.$options.filters.currency(amount.price)}`;
    },
  },
};
</script>
